import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Element } from "react-scroll";
import Header from "./components/Header";
import TitleBoard from "./components/TitleBoard";
import Who from "./components/Who";
import Testimonial from "./components/Testimonial";
import Partners from "./components/Partners";
import JoinUs from "./components/JoinUs";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./App.css";


function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Routes>
            {/* Main Route with Scrollable Sections */}
            <Route
              path="/"
              element={
                <>
                  <Header />

                  <Element name="home">
                    <TitleBoard />
                  </Element>

                  <Element name="testimonial">
                    <Who />
                  </Element>

                  <Element name="feedback">
                    <Testimonial />
                  </Element>

                  <Element name="partners">
                    <Partners />
                  </Element>

                  <Element name="joinUs">
                    <JoinUs />
                  </Element>

                  <Footer />
                </>
              }
            />

            {/* Routes for Privacy Policy and Terms and Conditions */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/join-us" element={<JoinUs />} />
            <Route path="/join-us/*" element={<JoinUs />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
