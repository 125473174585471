import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Grid,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-scroll";
import logo from "../assets/istash.png";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#333" }}>
      <Toolbar>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Link
              to="home"
              smooth={true}
              duration={500}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <img style={{ width: "50px" }} src={logo} alt="logo" />
            </Link>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: isMobile ? "none" : "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              {/* Desktop Buttons */}
              <Button color="inherit">
                <Link
                  to="home"
                  smooth={true}
                  duration={500}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Home
                </Link>
              </Button>
              <Button color="inherit">
                <Link
                  to="testimonial"
                  smooth={true}
                  duration={500}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Testimonial
                </Link>
              </Button>
              <Button color="inherit">
                <Link
                  to="feedback"
                  smooth={true}
                  duration={500}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Feedback
                </Link>
              </Button>
              <Button color="inherit">
                <Link
                  to="partners"
                  smooth={true}
                  duration={500}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Partners
                </Link>
              </Button>
              <Button color="inherit">
                <Link
                  to="joinUs"
                  smooth={true}
                  duration={500}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Join Us
                </Link>
              </Button>
            </Box>
            {/* Mobile Burger Icon */}
            <Box sx={{ display: isMobile ? "block" : "none" }}>
              <IconButton color="inherit" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {/* Mobile Menu Items */}
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="home"
                    smooth={true}
                    duration={500}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Home
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="testimonial"
                    smooth={true}
                    duration={500}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Testimonial
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="feedback"
                    smooth={true}
                    duration={500}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Feedback
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="partners"
                    smooth={true}
                    duration={500}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Partners
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="joinUs"
                    smooth={true}
                    duration={500}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Join Us
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
