import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

// const baseUrl = "http://localhost:4000/";
const baseUrl = "https://node.istash.shop/";

const httpRequestorInterceptor = (config: AxiosRequestConfig): any => {
  const headers = {
    Authorization: localStorage.accessToken,
    "x-melo-channel": "web",
    ...config.headers, // Spread existing headers to ensure no overrides happen unintentionally
  };

  return { ...config, headers }; // Ensure we're returning a config object that matches the expected type
};

// Define the response error interceptor
const httpResponseErrorInterceptor = (
  error: AxiosError
): Promise<AxiosError> => {
  // Handle errors (e.g., log or modify the error)
  console.error("Response error intercepted:", error);

  // // Redirect on specific status code, for example
  // if (error.response?.status === 401) {
  //   // Handle 401 errors, for example, by redirecting to login
  //   window.location.href = "/login";
  // }

  // Always reject the error or you'll swallow it
  return Promise.reject(error);
};

// Define the response success interceptor
const httpResponseSuccessInterceptor = (
  response: AxiosResponse
): AxiosResponse => {
  // You can modify or log the response here
  console.log("Response intercepted:", response);
  // Always return the response or you'll block the promise chain
  return response;
};

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 20000,
  headers: {
    "Content-type": "application/json",
  },
});

// For request interceptors
axiosInstance.interceptors.request.use(httpRequestorInterceptor);

// Adding the response interceptors
axiosInstance.interceptors.response.use(
  httpResponseSuccessInterceptor,
  httpResponseErrorInterceptor
);
export default axiosInstance;
