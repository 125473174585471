import React from "react";
import { Typography, Link, Box } from "@mui/material";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{ backgroundColor: "#333", padding: "20px 0", textAlign: "center" }}
    >
      <Typography sx={{ color: "white", marginBottom: "10px" }}>
        © {currentYear} by istash LLC. All Rights Reserved.
      </Typography>
      <Box>
        <Link
          href="https://web.istash.shop/terms-and-condition"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "white", textDecoration: "none", margin: "0 15px" }}
        >
          Terms and Conditions
        </Link>

        <Link
          href="https://web.istash.shop/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "white", textDecoration: "none", margin: "0 15px" }}
        >
          Privacy Policy
        </Link>
        <Typography
          sx={{
            fontFamily: "Assistant, sans-serif",
            color: "white",
            display: "flex",
            justifyContent: "center",
            p: "10px 10px 10px 10px",
          }}
        >
          Your data will not be transferred to any third party
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
