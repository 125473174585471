import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import policyData from "../assets/json/FAQ.json";

const Faq = () => {
  // Check if policyData is loading or empty
  if (!policyData || policyData.sections.length === 0) {
    return (
      <Container style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <Typography variant="h3" sx={{ p: "20px 0px 0px 0px" }}>
        FAQ
      </Typography>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        {policyData.sections.map((section: any, index: any) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                {section.number}. {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {section.summary && (
                <Typography variant="subtitle1" gutterBottom>
                  <em>In Short: {section.summary}</em>
                </Typography>
              )}
              {section.content.description && (
                <Typography variant="body1" paragraph>
                  {section.content.description}
                </Typography>
              )}
              {/* Render the reasons or other content based on the structure */}
              {Array.isArray(section.content.reasons) &&
                section.content.reasons.map((reason: any, idx: any) => (
                  <div key={idx}>
                    <Typography variant="body1" fontWeight="bold">
                      {reason.title}
                    </Typography>
                    <Typography variant="body1">
                      {reason.description}
                    </Typography>
                  </div>
                ))}
              {/* Render other content details for sections without summary or description */}
              {section.content.details && (
                <Typography variant="body1" paragraph>
                  {section.content.details}
                </Typography>
              )}
              {Array.isArray(
                section.content.PersonalInformationProvidedByYou
              ) && (
                <div>
                  <Typography variant="body1">
                    <strong>Personal Information Provided by You:</strong>
                  </Typography>
                  <ul>
                    {section.content.PersonalInformationProvidedByYou.map(
                      (item: any, idx: any) => (
                        <li key={idx}>{item}</li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </>
  );
};

export default Faq;
