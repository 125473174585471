import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

// Importing logos from assets folder
import logo1 from "../assets/logos/andiamo.png";
import logo2 from "../assets/logos/art.png";
import logo3 from "../assets/logos/artcity.png";
import logo4 from "../assets/logos/artful.png";
import logo5 from "../assets/logos/baba.png";
import logo6 from "../assets/logos/beauty.png";
import logo7 from "../assets/logos/firefly.png";
import logo8 from "../assets/logos/liquor.png";
import logo9 from "../assets/logos/lo.png";
import logo10 from "../assets/logos/moti.png";
import logo11 from "../assets/logos/scissorcrest3.jpg";
import logo12 from "../assets/logos/siri.jpg";
import logo13 from "../assets/logos/the.png";
import logo14 from "../assets/logos/trim.png";
import logo15 from "../assets/logos/umami.png";
import logo16 from "../assets/logos/verzeney.png";
import logo17 from "../assets/logos/vigo.png";

// Array of logos with their corresponding website URLs
const logoData = [
  {
    src: logo1,
    url: "https://www.andiamopizzariail.com/l3uafw01/restaurant/order-online",
  },
  { src: logo2, url: "https://artofindiancuisine.com" },
  { src: logo3, url: "https://artcity-website.com" },
  { src: logo4, url: "https://www.artfulframerstudios.com" },
  { src: logo5, url: "https://visitbobaheaven.com" },
  { src: logo6, url: "https://fabulashbeauty.studio" },
  { src: logo7, url: "https://www.fireflyburgers.us" },
  { src: logo8, url: "https://myliquorexpo.com" },
  { src: logo9, url: "https://www.lospanails.com" },
  { src: logo10, url: "https://www.thecolettecollection.com" },
  { src: logo11, url: "https://www.streetsoflondonsalon.com" },
  { src: logo12, url: "https://siriindianrestaurantonline.com" },
  { src: logo13, url: "https://www.thecolettecollection.com" },
  { src: logo14, url: "https://www.trimwax.com" },
  { src: logo15, url: "https://www.umamifromscratch.com" },
  { src: logo16, url: "https://www.verzenaychicago.com" },
  { src: logo17, url: "https://vigo-coffee.com" },
];

const Partners = () => {
  return (
    <div>
      <Grid
        container
        sx={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "20px 0px",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            p: "20px 0px 10px 0px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              fontFamily: "Assistant, sans-serif",
              color: "black",
            }}
          >
            Our retail partners
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ p: "20px 0" }}>
          <Divider
            sx={{
              backgroundColor: "black", // Ensures the color is visible
              height: "2px", // Makes it thicker
              width: "100%", // Full width divider
              mt: 2, // Adds some margin above
              mb: 2, // Adds some margin below
            }}
          />
        </Grid>

        <Grid
          container
          item
          spacing={3}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          justifyContent="center"
          alignItems="center"
        >
          {logoData.map((logo, index) => (
            <Grid key={index} item xs={6} sm={4} md={3} lg={2} xl={2}>
              <a
                href={logo.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "block" }} // Ensures the entire logo is clickable
              >
                <img
                  src={logo.src}
                  alt={`Partner logo ${index + 1}`}
                  style={{
                    width: "60%",
                    maxHeight: "150px",
                    objectFit: "contain",
                  }}
                />
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Partners;
