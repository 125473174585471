import { Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../utils/configurations";
import logo from "../assets/istash.png";

const TitleBoard = () => {
  return (
    <div>
      <Grid
        container
        sx={{
          background: colors.theme,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: {
            xs: "30px 10px",
            sm: "40px 20px",
            md: "50px 30px",
            lg: "50px 40px",
          }, // Responsive padding
        }}
      >
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", p:'20px 0px 0px 0px' }}>
          <img
            style={{ width: "250px",}}
            src={logo}
            alt="logo"
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontFamily: "Abhaya Libre, serif",
              color: "white",
              fontSize: {
                xs: "1.2rem",
                sm: "1.5rem",
                md: "1.7rem",
                lg: "2rem",
              }, 
              lineHeight: { xs: "0.8", sm: "1.0"}, 
            }}
          >
            A digital wallet that rewards
            <br />
            customers for shopping local
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TitleBoard;
