import React from "react";
import privacyPolicy from "../assets/json/privacyPolicy.json"
import Faq from "./Faq";
import { Grid } from '@mui/material';
import { colors } from "../utils/configurations";
import logo from "../assets/istash.png"


const PrivacyPolicy: any = () => {

const policyData = privacyPolicy
  return (
    <div>
      <Grid
        sx={{
          p: "20px 20px 20px 20px",
          background: colors.theme,
          color: "white",
        }}
      >
        <Grid sx={{display:'flex', justifyContent:'center'}}>

        <img style={{width:'250px'}} src={logo} alt="logo" />
        </Grid>

        <h1>Privacy Policy</h1>
        <p>
          <strong>Last Updated:</strong> {policyData.lastUpdated}
        </p>
        <p>{policyData.introduction.content}</p>

        {policyData.sections.map((section: any, index: any) => (
          <div key={index}>
            <h2>{section.title}</h2>
            <p>{section.content}</p>
          </div>
        ))}
        <Faq />
      </Grid>
    </div>
  );
};

export default PrivacyPolicy;
