import React from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { colors } from "../utils/configurations";
import facebook from "../assets/facebook.png";
import insta from "../assets/insta.png";
import linkedin from "../assets/linkedin.png";

const Testimonial = () => {
  return (
    <div>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          background: colors.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: { xs: "10px 0", sm: "20px 0" }, // Responsive padding
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h3"
            sx={{
              fontFamily: "Abhaya Libre, serif",
              color: "white",
              p: { xs: "10px 0", sm: "20px 0" },
            }}
          >
            istash Merchant Testimonials
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Abhaya Libre, serif",
              color: "white",
              // p: { xs: "5px 0", sm: "20px 0" },
              fontWeight: "bold",
            }}
          >
            CUT THE CREDIT (CORD)
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Abhaya Libre, serif",
              color: "white",
              // p: { xs: "5px 0", sm: "20px 0" },
              fontWeight: "bold",
            }}
          >
            EARN FREE CASH
          </Typography>
          <Typography
            sx={{
              fontSize: "26px",
              fontFamily: "Assistant, sans-serif",
              color: colors.text,
              p: { xs: "10px 0", sm: "20px 0" },
              fontWeight: "bold",
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",

              perspective: "500px",
            }}
          >
            Coming soon
            <br />
          
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            p: { xs: "10px 0", sm: "20px 10px" },
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap", // Allows wrapping on smaller screens
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column", // Stack video and name vertically
              alignItems: "center",
              mb: 2,
            }}
          >
            <video width="60%" controls playsInline preload="auto" muted>
              <source
                src="https://istash-landing.s3.amazonaws.com/feedback1.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            <Typography variant="h6" sx={{ color: "white", mt: 1 }}>
              Zareena, Trim
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
            }}
          >
            <video width="60%" controls playsInline preload="auto" muted>
              <source
                src="https://istash-landing.s3.amazonaws.com/feedback4.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <Typography variant="h6" sx={{ color: "white", mt: 1 }}>
              Aqueel, Verzenay
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
            }}
          >
            <video width="60%" controls playsInline preload="auto" muted>
              <source
                src="https://istash-landing.s3.amazonaws.com/feedback3.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <Typography variant="h6" sx={{ color: "white", mt: 1 }}>
              Dhruv, Moti Lincoln Park
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ p: "20px 0" }}>
          <Divider
            sx={{
              backgroundColor: "white", // Ensures the color is visible
              height: "2px", // Makes it thicker
              width: "100%", // Full width divider
              mt: 2, // Adds some margin above
              mb: 2, // Adds some margin below
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ zIndex: 2, p: { xs: "10px 0", sm: "20px 0" } }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "white",
              borderRadius: "25px",
              border: "1px solid white",
              display: "block",
              mx: "auto",
            }}
            onClick={() =>
              (window.location.href =
                "mailto:farah@istash.shop?subject=Contact&body=Message")
            }
          >
            Contact Us
          </Button>

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: "10px 0", sm: "20px 0" },
              flexWrap: "wrap", // Allows wrapping on smaller screens
            }}
          >
            <Grid item sx={{ p: "10px" }}>
              <img
                style={{ width: "50px", cursor: "pointer" }}
                src={facebook}
                alt="facebook"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/people/Istash/61566014381694/",
                    "_blank"
                  )
                }
              />
            </Grid>
            <Grid item sx={{ p: "10px" }}>
              <img
                style={{ width: "50px", cursor: "pointer" }}
                src={linkedin}
                alt="linkedin"
              />
            </Grid>
            <Grid item sx={{ p: "10px" }}>
              <img
                style={{ width: "50px", cursor: "pointer" }}
                src={insta}
                alt="insta"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/istash.shop/",
                    "_blank"
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            p: { xs: "10px", sm: "20px 10px" },
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Grid item xs={12} sm={6} sx={{ p: 1 }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Poppins sans-serif",
                color: "black",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
              }}
            >
              What’s in it for customers?
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "20px",
                fontFamily: "Assistant, sans-serif",
                color: "white",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              1. At least 3% cash back
              <br />
              2. Ability to spend and earn at every participating business
              <br /> 3. Freedom to move cash back to bank account
              <br /> 4. Earning referral free, more free cash
              <br />
              5. Laughing all the way to the bank
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ p: 1 }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Poppins sans-serif",
                color: "black",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
              }}
            >
              What’s in it for retailers?
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "20px",
                fontFamily: "Assistant, sans-serif",
                color: "white",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              1. No more transaction fees
              <br /> 2. Unparalleled customer loyalty
              <br />
              3. Free marketing on istash app and all social media
              <br /> 4. Simple and free to use
              <br />
              5. Safe, secure transactions
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Testimonial;
