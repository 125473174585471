import React from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import bg from "../assets/bg.jpg";
import { colors } from "../utils/configurations";
import facebook from "../assets/facebook.png";
import insta from "../assets/insta.png";
import linkedin from "../assets/linkedin.png";
import { Link } from "react-scroll";

const Who = () => {
  return (
    <div>
      <Grid
        container
        sx={{
          position: "relative",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          p: { xs: "10px 0px", sm: "20px 0px" },
          minHeight: "100vh",
        }}
      >
        {/* Translucent blue overlay */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(1, 52, 61, 0.9)",
            zIndex: 1,
          }}
        ></div>

        <Grid item xs={12} sx={{ zIndex: 2 }}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Assistant, sans-serif",
              color: colors.theme,
              textAlign: "center",
            }}
          >
            Who We Are
          </Typography>
          <Typography
            sx={{
              fontFamily: "Assistant, sans-serif",
              color: "white",
              textAlign: "center",
              p: { xs: "20px 10px", sm: "50px 0px" },
              fontSize: "22px",
            }}
          >
            Istash is a digital wallet that will revolutionize how
            <br />
            we transact. Our goal is to empower both small
            <br />
            businesses and their customers, making transaction <br />
            fees a thing of the past and rewarding customers
            <br />
            with at least 3% instant cash back, creating <br />
            incredible value for both{" "}
          </Typography>
          <Grid
            item
            xs={12}
            sx={{
              zIndex: 2,
              p: "20px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              to="joinUs"
              smooth={true}
              duration={500}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Button
                variant="outlined"
                sx={{ color: colors.theme, borderRadius: "25px" }}
              >
                To learn more subscribe below
              </Button>
            </Link>
          </Grid>
          <Divider
            sx={{
              p: "1px 0px",
              color: "white",
              backgroundColor: "white",
            }}
          />

          <Typography
            variant="h3"
            sx={{
              fontFamily: "Abhaya Libre, serif",
              color: "white",
              textAlign: "center",
              p: "20px 0px",
            }}
          >
            Merchant Testimonials
          </Typography>
          <Grid
            container
            spacing={2} // Add spacing between items
            sx={{
              p: { xs: "10px 20px", sm: "10px 200px" },
              justifyContent: "center",
            }} // Responsive padding and center alignment
          >
            {[
              "No credit card offers anything remotely close, neither does a digital wallet, what's the catch?",
              "3% and more instant cash back as loyalty to my customers? How do I sign up?",
              "Do you really mean you eliminate swipe fees?",
              "How come no one ever thought of this before?",
              "Too good to be true",
            ].map((testimonial, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ p: "5px" }}>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontFamily: "Assistant, sans-serif",
                    color: "white",
                    textAlign: "center",
                    p: "10px 0px",
                  }}
                >
                  "{testimonial}"
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ zIndex: 2, p: { xs: "10px 0", sm: "20px 0" } }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "white",
                borderRadius: "25px",
                border: "1px solid white",
                display: "block",
                mx: "auto",
              }}
              onClick={() =>
                (window.location.href =
                  "mailto:farah@istash.shop?subject=Contact&body=Message")
              }
            >
              Contact Us
            </Button>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: "10px 0", sm: "20px 0" },
                flexWrap: "wrap", // Allows wrapping on smaller screens
              }}
            >
              <Grid item sx={{ p: "10px" }}>
                <img
                  style={{ width: "50px", cursor: "pointer" }}
                  src={facebook}
                  alt="facebook"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/people/Istash/61566014381694/",
                      "_blank"
                    )
                  }
                />
              </Grid>
              <Grid item sx={{ p: "10px" }}>
                <img
                  style={{ width: "50px", cursor: "pointer" }}
                  src={linkedin}
                  alt="linkedin"
                />
              </Grid>
              <Grid item sx={{ p: "10px" }}>
                <img
                  style={{ width: "50px", cursor: "pointer" }}
                  src={insta}
                  alt="insta"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/istash.shop/",
                      "_blank"
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Who;
